<template>
  <div>
    <form @submit.prevent>
      <div class="mb-3">
        <input type="text" class="form-control" v-model="text">
      </div>
      <button type="submit" class="btn btn-primary" @click="submitImage">제출</button>
    </form>
    <img :src="imageUrl" v-show="imageUrl !== ''">
  </div>
</template>

<script setup>
import {ref} from 'vue'
import {Configuration, OpenAIApi} from "openai";

const configuration = new Configuration({
  apiKey: process.env.VUE_APP_OPENAI_API_KEY,
});
const openai = new OpenAIApi(configuration);

const text = ref("");
const imageUrl = ref("");

const submitImage = async () => {
  try {
    const response = await openai.createImage({prompt: text.value, size: "256x256"});
    console.log(response.data.data[0].url);
    imageUrl.value = response.data.data[0].url;
  } catch (error) {
    if (error.response) {
      console.log(error.response.status);
      console.log(error.response.data);
    } else {
      console.log(error.message);
    }
  }
};
</script>

<style scoped>

</style>
